import React from 'react';
import './report-form.css';
import { toast } from 'react-toastify';
import InputControl from './input-control';
import SelectionInputControl from './selection-input-control';
import IndexedDBService from './../../services/IndexedDBService';

class ReportForm extends React.Component {
    constructor(props) {
        super(props);

        const searchParams = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        const reportIDParam = Number(searchParams.ID);

        this.state = {
            reportID: reportIDParam,
            ShouldShowSignature: false,
            artworkDescriptionOptions: ['Sculpture', 'Painting'],
            materialOptions: ['Bronze', 'Stone', 'Metal', 'Marble', 'Alabaster', 'TerraCotta', 'Linen', 'Canvas'],
            mediumOptions: ['Acrylic', 'Oil', 'None']
        };
        this.numericalChar = new Set(['.', ',', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);

        this.handleinput = this.handleinput.bind(this);
    }

    componentDidMount() {
        this.getReportData();
    }

    getReportData() {
        const time = (new Date()).toISOString().split('T')[0];

        IndexedDBService.getItemByBind('reports', this.state.reportID).then((data) => {
            let formattedReport = {
                reportIDOnBoard: data.reportIDOnBoard || -1,
                JobNumber: data.JobNumber || '',
                Name: data.Name || '',
                CreatedOn: (new Date(data.CreatedOn)).toDateString() || time,
                ArtTitle: data.ArtTitle || '',
                Artist: data.Artist || '',
                ArtCreationDate: data.ArtCreationDate || '',
                ArtID: data.ArtID || '',
                AltID: data.AltID || '',
                Client: data.Client || '',
                ArtistSig: data.ArtistSig || '',
                LocalThumbnailImageID: data.LocalThumbnailImageID || -1,
                ThumbnailImageID: data.ThumbnailImageID || -1,
                ArtDesc: data.ArtDesc || '',
                ArtPieceArray: data.ArtPieceArray || [{ dimensions: { width: null, length: null, height: null }, mediums: [null], materials: [null] }],
                ConditionNotes: data.ConditionNotes || '',
                Examiner: data.Examiner || '',
                ExaminerTitle: data.ExaminerTitle || '',
                ExaminationLocation: data.ExaminationLocation || '',
                ExaminationDate: data.ExaminationDate || time,
                ShouldShowSignature: Boolean(data.ArtistSig)
            };
            if (Object.hasOwn(data, 'DownloadedOn')) {
                formattedReport.DownloadedOn = data.DownloadedOn;
            }
            this.setState(formattedReport);
        }).catch((error) => { console.error(error); toast.error('Error getting report  data ' + error); });
    }

    saveReportToIDB(reportToSave) {
        IndexedDBService.putData('reports', reportToSave).then((result) => {
            toast.success('Saved report ID: ' + result.event.target.result);
        }).catch((error) => { console.error(error); toast.error('Error saving report ' + error); });
    }

    isNumber(num) {
        return (typeof num === 'string' || typeof num === 'number') && !isNaN(num - 0) && num !== '';
    }

    hasNonNumericCharacter(str) {
        if (!str) return false;
        return (/[^0-9\-.]/.test(str));
    }

    validateReportData(data){
        const errors = [];

        if (this.hasNonNumericCharacter(data.ArtCreationDate)) {
            errors.push('Art Creation Date contains non-number characters');
        }

        for (let i = 0; i < data.ArtPieceArray.length; i++) {
            console.log('art piece', data.ArtPieceArray[i]);
            if(!data.ArtPieceArray[i].dimensions.length || data.ArtPieceArray[i].dimensions.length == '0'){
                errors.push('The length must be set and greater than 0 for Art Piece ' + (i + 1));
            }
            if(!data.ArtPieceArray[i].dimensions.width || data.ArtPieceArray[i].dimensions.width == '0'){
                errors.push('The width must be set and greater than 0 for Art Piece ' + (i + 1));
            }
            if(!data.ArtPieceArray[i].dimensions.height || data.ArtPieceArray[i].dimensions.height == '0'){
                errors.push('The height must be set and greater than 0 for Art Piece ' + (i + 1));
            }
            if(data.ArtPieceArray[i].mediums.includes('None') && data.ArtPieceArray[i].mediums.length > 1){
                errors.push('You cannot have multiple mediums on Art Piece ' + (i + 1) + ' as it already has a medium stating "none"');
            }
            if(data.ArtPieceArray[i].materials.indexOf(null) !== -1){
                errors.push('You have not picked a material for Art Piece ' + (i + 1));
            }
            if(data.ArtPieceArray[i].mediums.indexOf(null) !== -1){
                errors.push('You have not picked a medium for Art Piece ' + (i + 1));
                
            }
            if (this.hasNonNumericCharacter(data.ArtPieceArray[i].dimensions.width) || this.hasNonNumericCharacter(data.ArtPieceArray[i].dimensions.length) || this.hasNonNumericCharacter(data.ArtPieceArray[i].dimensions.height)) {
                errors.push('One of the art piece dimensions contains non-number characters');
            }
        }

        return errors;
    }

    saveReport() {
        const updatedData = {
            JobNumber: this.state.JobNumber,
            CreatedOn: this.state.CreatedOn,
            Name: this.state.Name,
            ArtTitle: this.state.ArtTitle,
            Artist: this.state.Artist,
            ArtID: this.state.ArtID,
            AltID: this.state.AltID,
            Client: this.state.Client,
            ArtistSig: this.state.ArtistSig,
            ArtDesc: this.state.ArtDesc,
            ArtCreationDate: this.state.ArtCreationDate,
            ArtPieceArray: this.state.ArtPieceArray,
            ConditionNotes: this.state.ConditionNotes,
            Examiner: this.state.Examiner,
            ExaminerTitle: this.state.ExaminerTitle,
            ExaminationLocation: this.state.ExaminationLocation,
            ExaminationDate: this.state.ExaminationDate,
            ThumbnailImageID: this.state.ThumbnailImageID
        };

        const validationErrors = this.validateReportData(updatedData);
        if(validationErrors.length > 0){
            toast.error(validationErrors.join(', '));
            return;
        }

        if(!navigator.onLine) {
            this.saveReportToIDB({ ...updatedData, LocalThumbnailImageID: this.state.LocalThumbnailImageID, id: this.state.reportID, JobNumber: this.state.JobNumber, CreatedOn: this.state.CreatedOn });
            toast.success('Report uploaded sucessfully');
            window.location.href = '/';
        }

        const reportIDOnBoard = this.state.reportIDOnBoard;
        let endpoint = 'https://mcheck.alphademo.co.uk/api/reports/';
        let method = 'POST';
        if (this.isNumber(reportIDOnBoard) && reportIDOnBoard !== -1) {
            endpoint += reportIDOnBoard;
            method = 'PUT';
        }

        fetch(endpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
            },
            body: JSON.stringify({
                ...updatedData,
                ArtPieceArray: JSON.stringify(updatedData.ArtPieceArray)
            })
        }).then(res => {
            if (!res.ok) {
                toast.error('Error accessing online database: ' + res.statusText);
            } else if (!(this.isNumber(reportIDOnBoard) && reportIDOnBoard !== -1)) {
                res.json().then((jsonres) => {
                    if (jsonres.error) {
                        toast.error('Error uploading report: ' + jsonres.error.message);
                        return;
                    }

                    updatedData.reportIDOnBoard = jsonres.ID;
                    if (Object.hasOwn(this.state, 'DownloadedOn')) {
                        updatedData.DownloadedOn = this.state.DownloadedOn;
                    }
                    this.setState({ reportIDOnBoard: jsonres.ID });
                    this.saveReportToIDB({ ...updatedData, LocalThumbnailImageID: this.state.LocalThumbnailImageID, id: this.state.reportID, reportIDOnBoard: jsonres.ID, JobNumber: this.state.JobNumber, CreatedOn: this.state.CreatedOn });
                    toast.success('Report uploaded sucessfully');
                    window.location.href = '/';
                });
            } else {
                if (Object.hasOwn(this.state, 'DownloadedOn')) {
                    updatedData.DownloadedOn = this.state.DownloadedOn;
                }
                this.saveReportToIDB({ ...updatedData, LocalThumbnailImageID: this.state.LocalThumbnailImageID, id: this.state.reportID, reportIDOnBoard: this.state.reportIDOnBoard, JobNumber: this.state.JobNumber, CreatedOn: this.state.CreatedOn });
                toast.success('Report uploaded sucessfully');
                window.location.href = '/';
            }
        }).catch((err) => {
            toast.error('Error uploading report', err);
            console.error(err);
        });
    }

    addNewArrayItem(type, id) {
        const currentArtPieces = this.state.ArtPieceArray;
        if (type === 'artPiece') {
            currentArtPieces.push({ dimensions: { width: null, length: null, height: null }, mediums: [null], materials: [null] });
        } else if (type === 'material') {
            currentArtPieces[id].materials.push(null);
        } else if (type === 'medium') {
            currentArtPieces[id].mediums.push(null);
        }
        this.setState(currentArtPieces);
    }

    deleteArrayItem(id) {
        const split = id.split('-');
        const type = split[0];
        const ArtPieceIndex = split[1];
        if (window.confirm('Are you sure you want to delete this ' + type + '?')) {
            if (type === 'artPiece') {
                let tempArtPieces = this.state.ArtPieceArray;
                tempArtPieces.splice(ArtPieceIndex, 1);
                this.setState({ ArtPieceArray: tempArtPieces });
            } else if (type === 'material') {
                const tempArtPieces = this.state.ArtPieceArray;
                const mediumIndex = split[2];
                tempArtPieces[ArtPieceIndex].materials.splice(mediumIndex, 1);
                if(tempArtPieces[ArtPieceIndex].materials.length === 0  && tempArtPieces[ArtPieceIndex].mediums.length === 0 ){
                    if(window.confirm('Would you like to delete the art piece')){
                        tempArtPieces.splice(ArtPieceIndex, 1);
                    }
                    this.setState({ ArtPieceArray: tempArtPieces });
                } else {
                    this.setState({ ArtPieceArray: tempArtPieces });
                }
            } else if (type === 'medium') {
                const tempArtPieces = this.state.ArtPieceArray;
                const materialIndex = split[2];
                tempArtPieces[ArtPieceIndex].mediums.splice(materialIndex, 1);
                if(tempArtPieces[ArtPieceIndex].materials.length === 0  && tempArtPieces[ArtPieceIndex].mediums.length === 0 ){
                    if(window.confirm('Would you like to delete the art piece')){
                        tempArtPieces.splice(ArtPieceIndex, 1);
                    }
                    this.setState({ ArtPieceArray: tempArtPieces });
                } else {
                    this.setState({ ArtPieceArray: tempArtPieces });
                }
            }
        }
    }


    handleinput({ e, key, value, inputType }) {
        if (inputType === 'checkbox') {
            value = e.target.checked;
        } else if (inputType === 'date') {
            //YYYY-MM-DD
            console.log(value);
        }
        const newObject = {};
        newObject[key] = value;
        this.setState(newObject);
    }

    handleArrayInput(key, value) {
        const split = key.split('-');
        const artPieceID = split[1];
        const inputType = split[2];
        const currentArtPieces = this.state.ArtPieceArray;
        const currentArtPiece = currentArtPieces[artPieceID];
        if (inputType === 'length') {
            currentArtPiece.dimensions.length = value;
        } else if (inputType === 'width') {
            currentArtPiece.dimensions.width = value;
        } else if (inputType === 'height') {
            currentArtPiece.dimensions.height = value;
        } else if (inputType === 'medium') {
            const currentMediumID = split[3];
            const currentMediums = currentArtPiece.mediums;
            currentMediums[currentMediumID] = value;
            currentArtPiece.mediums = currentMediums;
        } else if (inputType === 'material') {
            const currentMaterialID = split[3];
            const currentMaterials = currentArtPiece.materials;
            currentMaterials[currentMaterialID] = value;
            currentArtPiece.materials = currentMaterials;
        }
        currentArtPieces[artPieceID] = currentArtPiece;
        this.setState({ ArtPieceArray: currentArtPieces });
    }

    handleBlur(e) {
        if (/[^0-9\-.]/.test(e.target.value)) {
            e.target.classList.add('error');
        } else {
            e.target.classList.remove('error');
        }
    }

    render() {
        const { Name, JobNumber, CreatedOn, reportID, reportIDOnBoard, artworkDescriptionOptions, materialOptions, mediumOptions,
            ArtDesc, ArtTitle, Artist, ArtCreationDate, ArtID, AltID, Client, ShouldShowSignature, ArtistSig,
            ConditionNotes, Examiner, ExaminerTitle, ExaminationLocation, ExaminationDate, ArtPieceArray } = this.state;

        let ArtPieceArrayElement = <div />;

        if (ArtPieceArray) {
            ArtPieceArrayElement = ArtPieceArray.map((artPiece, artPieceIndex) =>
                <div key={artPieceIndex} className='artpiece-list-container'>
                    <div className='art-dimensions'>
                        <p>Art Piece {artPieceIndex + 1} <button className="danger delete-dimension-button" onClick={() => { this.deleteArrayItem('artPiece-' + artPieceIndex); }}><i className="fa-solid fa-circle-minus" /></button></p>
                        <div className="form-group">
                            <label>Length (cm)</label>
                            <input onBlur={this.handleBlur} id={'artPiece-' + artPieceIndex + '-length'} placeholder="e.g. 123" value={artPiece.dimensions.length} onChange={e => this.handleArrayInput(e.target.id, e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Width (cm)</label>
                            <input onBlur={this.handleBlur} id={'artPiece-' + artPieceIndex + '-width'} placeholder="e.g. 123" value={artPiece.dimensions.width} onChange={e => this.handleArrayInput(e.target.id, e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Height (cm)</label>
                            <input onBlur={this.handleBlur} id={'artPiece-' + artPieceIndex + '-height'} placeholder="e.g. 123" value={artPiece.dimensions.height} onChange={e => this.handleArrayInput(e.target.id, e.target.value)} />
                        </div>
                    </div>
                    <div className='materials'>
                        <label>Materials <button className="primary" onClick={() => { this.addNewArrayItem('material', artPieceIndex); }}><i className="fa-solid fa-plus" /></button></label>
                        {artPiece.materials.map((material, materialIndex) =>
                            <div key={materialIndex} className="form-group">
                                <button className="danger delete-dimension-button" onClick={() => { this.deleteArrayItem('material-' + artPieceIndex + '-' + materialIndex); }}><i className="fa-solid fa-circle-minus" /></button>
                                <SelectionInputControl options={materialOptions} allowCustomOption={true} selectedValue={material} handleInput={({ value }) => { this.handleArrayInput('artPiece-' + artPieceIndex + '-material-' + materialIndex, value); }} inputId={'artPiece-' + artPieceIndex + '-material-' + materialIndex} />
                            </div>

                        )}
                    </div>
                    <div className='mediums'>
                        <label>Mediums <button className="primary" onClick={() => { this.addNewArrayItem('medium', artPieceIndex); }}><i className="fa-solid fa-plus" /></button></label>
                        {artPiece.mediums.map((medium, mediumIndex) =>
                            <div key={mediumIndex} className="form-group">
                                <button className="danger delete-dimension-button" onClick={() => { this.deleteArrayItem('medium-' + artPieceIndex + '-' + mediumIndex); }}><i className="fa-solid fa-circle-minus" /></button>
                                <SelectionInputControl options={mediumOptions} allowCustomOption={true} selectedValue={medium} handleInput={({ value }) => { this.handleArrayInput('artPiece-' + artPieceIndex + '-medium-' + mediumIndex, value); }} inputId={'artPiece-' + artPieceIndex + '-medium-' + mediumIndex} />
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <div className="form-container">
                <h1>Report Form: {reportID}</h1>
                <div className="form">
                    <section>
                        <p>Report Details</p>
                        <div>
                            <InputControl label="Report Name" inputClass="medium" placeholder="e.g. Pre Install Images" value={Name} handleInput={this.handleinput} inputId="Name" />
                            <InputControl label="Job Number" inputClass="small" placeholder="e.g. MD0001" value={JobNumber} handleInput={this.handleinput} inputId="JobNumber" />
                            <InputControl label="Report Created On" inputClass="small" value={CreatedOn} disabled={true} />
                            <InputControl label="Database ID" inputClass="small" value={reportIDOnBoard === -1 ? 'Not Uploaded' : reportIDOnBoard} disabled={true} />
                        </div>
                    </section>
                    <section>
                        <p>Artwork Details</p>
                        <div>
                            <div className='custom-form-group'>
                                <SelectionInputControl label="Artwork Description" options={artworkDescriptionOptions} allowCustomOption={true} selectedValue={ArtDesc} handleInput={this.handleinput} inputId="ArtDesc" />
                            </div>

                            <InputControl label="Artwork Title" inputClass="big" placeholder="e.g. The Tree Of Life" value={ArtTitle} handleInput={this.handleinput} inputId="ArtTitle" />
                            <InputControl label="Artist/Creator" inputClass="medium" placeholder="e.g. David Salle" value={Artist} handleInput={this.handleinput} inputId="Artist" />
                            <InputControl label="Artwork Creation Date" type="num" inputClass="small" placeholder="e.g. 2020" value={ArtCreationDate} handleInput={this.handleinput} inputId="ArtCreationDate" />
                            <InputControl label="Client Reference" inputClass="small" placeholder="e.g. L2534/7974" value={ArtID} handleInput={this.handleinput} inputId="ArtID" />
                            <InputControl label="Alternate ID" inputClass="small" placeholder="e.g. 1001" value={AltID} handleInput={this.handleinput} inputId="AltID" />
                            <InputControl label="Client" inputClass="small" placeholder="e.g. Skarstedt" value={Client} handleInput={this.handleinput} inputId="Client" />
                            <div className='custom-form-group'>
                                <InputControl label="Has Artist Signature?" type="checkbox" inputClass="small" value={ShouldShowSignature} handleInput={this.handleinput} inputId="ShouldShowSignature" />
                                {ShouldShowSignature && <InputControl label="Artist Signature" inputClass="small" placeholder="e.g. Jess Mark 2023" value={ArtistSig} handleInput={this.handleinput} inputId="ArtistSig" />}
                            </div>
                        </div>
                    </section>
                    <section>
                        <p>Art Pieces <button className="primary" onClick={() => { this.addNewArrayItem('artPiece'); }}><i className="fa-solid fa-plus" /></button></p>
                        <div>
                            {ArtPieceArrayElement}
                        </div>
                    </section>
                    <section>
                        <p>Condition</p>
                        <div>
                            <InputControl label="Overall Condition Notes" inputClass="medium" placeholder="e.g. fair condition. no visible damage..." value={ConditionNotes} handleInput={this.handleinput} inputId="ConditionNotes" />
                            <InputControl label="Examiner" inputClass="small" placeholder="e.g. Tamas P." value={Examiner} handleInput={this.handleinput} inputId="Examiner" />
                            <InputControl label="Examiner Job Title" inputClass="small" placeholder="e.g. technician" value={ExaminerTitle} handleInput={this.handleinput} inputId="ExaminerTitle" />
                            <InputControl label="Examination Location" inputClass="small" placeholder="e.g. MTEC Ware" value={ExaminationLocation} handleInput={this.handleinput} inputId="ExaminationLocation" />
                            <InputControl label="Examination Date" type="date" inputClass="small" placeholder="e.g. 21/07/2023" value={ExaminationDate} handleInput={this.handleinput} inputId="ExaminationDate" />
                        </div>
                    </section>
                    <footer>
                        <button onClick={() => { this.saveReport(); }}>Save</button>
                    </footer>
                </div>
            </div>

        );
    }
}

export default ReportForm;