import React from 'react';
import './create-report-pdf.css';
import { toast } from 'react-toastify';
import { jsPDF } from 'jspdf';
import logo from '../../assets/MTEC_LOGO.jpg';


class CreateReportPDF extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: [],
            reportData: {}
        };
    }

    async getReportData() {
        const reportID = this.props.reportID;
        const res = await (fetch('https://mcheck.alphademo.co.uk/api/reports/' + reportID,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
                }
            }
        ));
        if (res.status === 404) {
            toast.error('Report not found: ' + reportID);
            return;
        } else if (res.status !== 200) {
            toast.error('Error getting image: ' + reportID);
            return;
        }
        const data = await res.json();
        this.setState({
            reportData: {
                jobNumber: data.jobnumber || '',
                Name: data.Name || '',
                CreatedOn: (new Date(data.CreatedOn)).toDateString() || '',
                ArtTitle: data.ArtTitle || '',
                Artist: data.Artist || '',
                ArtCreationDate: data.ArtCreationDate || '',
                ArtID: data.ArtID || '',
                AltID: data.AltID || '',
                Client: data.Client || '',
                ArtistSig: data.ArtistSig || '',
                ArtDesc: data.ArtDesc || '',
                ThumbnailImageID: data.ThumbnailImageID || -1,
                ArtPieceArray: data.ArtPieceArray || [{ dimensions: { width: 'Not set', length: 'Not set', height: 'Not set' }, mediums: [null], materials: [null] }],
                ConditionNotes: data.ConditionNotes || '',
                Examiner: data.Examiner || '',
                ExaminerTitle: data.ExaminerTitle || '',
                ExaminationLocation: data.ExaminationLocation || '',
                ExaminationDate: new Date(data.ExaminationDate).toLocaleString('en-GB', { day:'2-digit', month: 'short', year:'numeric'}) || ''
            }
        });
    }

    createNewPage(doc, config) {
        doc.addPage();
        config.pages++;
        config.currentY = config.topMargin + config.headerHeight;

        this.createHeader(doc, config);
        this.createFooter(doc, config);
    }

    checkIfNewPageNeeded(doc, config, proposedHeightIncrease) {
        if (
            (config.currentY > (config.pageHeight - config.footerHeight)) ||
            (config.currentY + proposedHeightIncrease > (config.pageHeight - config.footerHeight))) 
            {
            this.createNewPage(doc, config);
        }
    }

    writeFormFieldToPDF(formField, doc, config) {
        const wrappedText = doc.splitTextToSize(formField.value, config.pageWidth - (config.leftMargin * 2));
        const textHeight = wrappedText.length * config.lineHeight;
        this.checkIfNewPageNeeded(doc, config, (config.lineHeight + textHeight));

        doc.setFontSize(config.fontSize);
        doc.setFillColor(230, 230, 230);
        doc.setTextColor(0, 0, 0);
        doc.rect(config.leftMargin, (config.currentY - config.lineHeight), config.pageWidth - (config.leftMargin * 2), config.lineHeight, 'F');
        doc.text(config.leftMargin + config.padding, config.currentY - config.padding, formField.label);
        config.currentY += config.lineHeight;


        doc.setDrawColor(230, 230, 230);
        doc.rect(config.leftMargin, (config.currentY - config.lineHeight), config.pageWidth - (config.leftMargin * 2), textHeight, 'S');
        for (let i = 0; i < wrappedText.length; i++) {
            doc.text(config.leftMargin + config.padding, config.currentY - config.padding, wrappedText[i]);
            config.currentY += config.lineHeight;
        }
        config.currentY += config.lineMargin;
    }

    writeCategoryHeadingToPDF(heading, doc, config) {
        this.checkIfNewPageNeeded(doc, config, config.lineHeight);
        doc.setTextColor(32, 178, 170);
        doc.setFontSize('16');
        doc.text(config.leftMargin, config.currentY, heading);
        config.currentY += config.lineHeight;
        config.currentY += config.lineMargin;
    }

    writeArtDimensions(reportData, doc, config) {
        const textHeight = reportData.ArtPieceArray.length * config.lineHeight;
        this.checkIfNewPageNeeded(doc, config, textHeight);
        doc.setFontSize(config.fontSize);
        doc.setFillColor(230, 230, 230);
        doc.setTextColor(0, 0, 0);
        doc.rect(config.leftMargin, (config.currentY - config.lineHeight), config.pageWidth - (config.leftMargin * 2), config.lineHeight, 'F');
        doc.text(config.leftMargin + config.padding, config.currentY - config.padding, 'Artwork Dimensions');
        config.currentY += config.lineHeight;

        doc.setDrawColor(230, 230, 230);

        doc.rect(config.leftMargin, (config.currentY - config.lineHeight), config.pageWidth - (config.leftMargin * 2), textHeight, 'S');

        for (let i = 0; i < reportData.ArtPieceArray.length; i++) {
            let textToWrite = 'Piece ' + (i + 1) + '. L: ' + (reportData.ArtPieceArray[i].dimensions.length || 'Not set') + ' W: ' + (reportData.ArtPieceArray[i].dimensions.width || 'Not set') + ' H: ' + (reportData.ArtPieceArray[i].dimensions.height || 'Not set');
            textToWrite += '. Material: ';
            for (let j = 0; j < reportData.ArtPieceArray[i].materials.length; j++) {
                textToWrite += (reportData.ArtPieceArray[i].materials[j] || 'Not set') + '/';
            }
            textToWrite = textToWrite.substring(0, textToWrite.length - 1);
            textToWrite += '. Medium: ';
            for (let j = 0; j < reportData.ArtPieceArray[i].mediums.length; j++) {
                textToWrite += (reportData.ArtPieceArray[i].mediums[j] || 'Not set') + '/';
            }
            textToWrite = textToWrite.substring(0, textToWrite.length - 1);
            doc.text(config.leftMargin + config.padding, config.currentY - config.padding, textToWrite);
            config.currentY += config.lineHeight;
        }

        config.currentY += config.lineMargin;
    }

    writeFormToPDF(reportData, doc, config) {
        this.writeCategoryHeadingToPDF('Object Details', doc, config);
        this.writeFormFieldToPDF({ label: 'Report Name', value: reportData.Name }, doc, config);
        this.writeFormFieldToPDF({ label: 'Artwork Title', value: reportData.ArtTitle }, doc, config);
        this.writeFormFieldToPDF({ label: 'Artist', value: reportData.Artist }, doc, config);
        this.writeFormFieldToPDF({ label: 'Artwork Created In', value: reportData.ArtCreationDate }, doc, config);
        this.writeFormFieldToPDF({ label: 'Client Reference', value: reportData.ArtID }, doc, config);
        this.writeFormFieldToPDF({ label: 'Alternate ID', value: reportData.AltID }, doc, config);
        this.writeFormFieldToPDF({ label: 'Client', value: reportData.Client }, doc, config);
        if (reportData.ArtistSig) {
            this.writeFormFieldToPDF({ label: 'Artist Signature', value: reportData.ArtistSig }, doc, config);
        }
        this.writeFormFieldToPDF({ label: 'Artwork Description', value: reportData.ArtDesc }, doc, config);

        this.writeCategoryHeadingToPDF('Artwork Pieces', doc, config);
        this.writeArtDimensions(reportData, doc, config);

        this.writeCategoryHeadingToPDF('Examination', doc, config);
        this.writeFormFieldToPDF({ label: 'Condition Notes', value: reportData.ConditionNotes }, doc, config);
        this.writeFormFieldToPDF({ label: 'Examined By', value: reportData.Examiner }, doc, config);
        this.writeFormFieldToPDF({ label: 'Examiner Title', value: reportData.ExaminerTitle }, doc, config);
        this.writeFormFieldToPDF({ label: 'Examination Location', value: reportData.ExaminationLocation }, doc, config);
        this.writeFormFieldToPDF({ label: 'Examination Date', value: reportData.ExaminationDate }, doc, config);
    }

    createHeader(doc, config) {
        doc.addImage(logo, 'png', config.leftMargin, config.topMargin, config.headerHeight, config.headerHeight, undefined, 'FAST');
        if (config.addHeaderMargin) {
            config.currentY += config.topMargin;
        }
    }

    createFooter(doc, config) {
        doc.line(config.leftMargin, config.pageHeight - config.footerHeight, config.pageWidth - config.leftMargin, config.pageHeight - config.footerHeight);
        doc.setFontSize('9');
        doc.setTextColor(0, 0, 0);
        doc.text(config.pageWidth - 100, (config.pageHeight - (config.footerHeight / 2)), 'Page ' + config.pages);
    }

    stampAddress(doc, config, x, y) {
        doc.setFontSize('9');
        doc.setTextColor(0, 0, 0);

        doc.text(x, y, 'MTEC Fine Art');

        doc.text(x, y + (config.lineMargin * 2), '10 Gentlemens Field');
        doc.text(x, y + (config.lineMargin * 3), 'Westmill Road');
        doc.text(x, y + (config.lineMargin * 4), 'Ware');
        doc.text(x, y + (config.lineMargin * 5), 'SG12 0EF');

        doc.text(x, y + (config.lineMargin * 7), 'T 01920 461 800');
        doc.text(x, y + (config.lineMargin * 8), 'E info@mtecfreightgroup.com');
    }

    stampDetails(doc, config, reportData, x, y) {
        doc.setFontSize('9');
        doc.setTextColor(0, 0, 0);

        doc.text(x, y, reportData.ArtID.toString());
        doc.text(x, y + (config.lineMargin * 2), reportData.Artist);
        doc.text(x, y + (config.lineMargin * 3), reportData.ArtTitle);

        doc.text(x, y + (config.lineMargin * 5), reportData.ArtDesc);
        for (let i = 0; i < reportData.ArtPieceArray.length; i++) {
            const textToWrite = 'Piece ' + (i + 1) + '. L: ' + (reportData.ArtPieceArray[i].dimensions.length || 'Not set') + ' W: ' + (reportData.ArtPieceArray[i].dimensions.width || 'Not set') + ' H: ' + (reportData.ArtPieceArray[i].dimensions.height || 'Not set');
            doc.text(x, y + (config.lineMargin * (5 + i + 1)), textToWrite);
        }
        // doc.line(x, y + (config.lineMargin * (6 + artDimensions.length)), (config.pageWidth / 2) - config.leftMargin, y + (config.lineMargin * (6 + artDimensions.length)));
        // doc.text(x, y + (config.lineMargin * (8 + artDimensions.length)), 'Examined by ' + reportData.Examiner);
        // doc.text(x, y + (config.lineMargin * (9 + artDimensions.length)), reportData.ExaminationDate.toString());
    }

    createCoverPage(doc, config, reportData) {
        this.createHeader(doc, config);
        this.createFooter(doc, config);

        doc.setFontSize('16');
        doc.text((config.pageWidth / 2 + 50), (config.pageWidth / 2 - 50), 'MTEC CONDITION REPORT');
        doc.line((config.pageWidth / 2 + 50), (config.pageWidth / 2 - 50) + 50, config.pageWidth, (config.pageWidth / 2 - 50) + 50);

        const frontImageIndex = this.state.images.findIndex(e => e.id === this.state.reportData.ThumbnailImageID);
        const frontImage = this.state.images[frontImageIndex];
        if (frontImage) {
            const imageProps = doc.getImageProperties(frontImage.src);
            const constrainedWidth = Math.floor((config.pageWidth - (config.leftMargin * 2)) / 2);
            const constrainedHeight = Math.floor((imageProps.height / imageProps.width) * constrainedWidth);
            doc.addImage(frontImage.src, 'PNG', config.pageWidth / 2, (config.pageWidth / 2) + config.lineHeight, constrainedWidth, constrainedHeight, undefined, 'FAST');
        }
        const addressStartingHeight = (config.pageHeight / 3) * 2;
        this.stampAddress(doc, config, config.leftMargin, addressStartingHeight);
        const detailsStartingHeight = (config.pageHeight / 3);
        this.stampDetails(doc, config, reportData, config.leftMargin, detailsStartingHeight);

        this.createNewPage(doc, config);
    }

    createPDF() {
        const doc = new jsPDF('p', 'pt', 'a4', true);
        const config = {
            pages: 1,
            fontSize: '11',
            footerHeight: 35,
            headerHeight: 35,
            leftMargin: 35,
            topMargin: 35,
            lineHeight: 15,
            lineMargin: 10,
            imageMargin: 10,
            padding: 5,
            pageHeight: doc.internal.pageSize.height,
            pageWidth: doc.internal.pageSize.width,
            currentY: 35,
            addHeaderMargin: true
        };
        const { reportData } = this.state;
        reportData.ArtPieceArray = JSON.parse(reportData.ArtPieceArray);
        console.log(reportData);


        this.createCoverPage(doc, config, reportData);
        this.writeFormToPDF(reportData, doc, config);

        config.addHeaderMargin = false;
        const images = this.state.images;
        images.sort((a, b) => a.SeqNo - b.SeqNo);
        for (let i = 0; i < images.length; i++) {
            const imageProps = doc.getImageProperties(images[i].src);
            const pageYMargin = (config.topMargin + config.headerHeight + config.footerHeight);
            const availableY = config.pageHeight - pageYMargin - 5;
            const halfAvailableY = availableY / 2;
            let constrainedHeight = (halfAvailableY - config.imageMargin);

            const constrainedWidth = Math.min(Math.floor((imageProps.width / imageProps.height) * constrainedHeight), config.pageWidth - (config.leftMargin * 2));
            constrainedHeight = Math.floor((imageProps.height / imageProps.width) * constrainedWidth);

            const xPosition = ((config.pageWidth - constrainedWidth) / 2);
            this.checkIfNewPageNeeded(doc, config, constrainedHeight + config.imageMargin);
            doc.addImage(images[i].src, 'PNG', xPosition, config.currentY + 5, constrainedWidth, constrainedHeight, undefined, 'FAST');
            config.currentY += constrainedHeight + config.imageMargin;
        }
        doc.save('report.pdf');
    }

    convertPNGtoJPEG(pngBlob, quality = 0.9) {
        return new Promise((resolve) => {
            const image = new Image();

            image.onload = function () {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                canvas.toBlob(function (jpegBlob) {
                    resolve(jpegBlob);
                }, 'image/jpeg', quality);
            };

            image.src = URL.createObjectURL(pngBlob);
        });
    }

    async getImageData(image) {
        const res = await (fetch('https://mcheck.alphademo.co.uk/api/files/' + image.Name,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
                }
            }
        ));
        if (res.status === 404) {
            toast.error('Image not found: ' + image.Name);
            return;
        } else if (res.status !== 200) {
            toast.error('Error getting image: ' + image.Name);
            return;
        }

        const blob = await res.blob();
        const jpeg = await this.convertPNGtoJPEG(blob);
        const images = this.state.images;
        images.push({ id: image.ID, src: URL.createObjectURL(jpeg), SeqNo: image.SeqNo });
        if (image.isThumnail) {
            this.setState({ thumbnailImageIndex: images.length - 1 });
        }
        this.setState({ images });
    }

    async getAllImages() {
        return new Promise((resolve, reject) => {
            const tokenEndpointForAllImages = 'https://mcheck.alphademo.co.uk/api/reports/' + this.props.reportID + '/images';
            fetch(tokenEndpointForAllImages, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
                }
            }).then(res => {
                res.json().then(async (jsonres) => {
                    if (jsonres.error) {
                        toast.error('Error getting images' + jsonres.error.message);
                    }
                    for (let image of jsonres) {
                        await this.getImageData(image);
                    }
                    resolve();
                });
            }).catch((err) => {
                toast.error('Error gettings images', err);
                console.error(err);
                reject();
            });
        });
    }

    async generateReport() {
        this.props.setIsGenerating(true);
        await this.getReportData();
        await this.getAllImages();
        this.createPDF();
        this.props.setIsGenerating(false);
    }

    render() {
        return (
            <button className="primary create-pdf-button" disabled={this.props.isUploading} onClick={() => {
                toast.promise(this.generateReport(), {
                    pending: 'Generating PDF',
                    success: 'PDF Generated',
                    error: 'Failed to generate PDF'
                });
            }}><i className="fa-solid fa-file-pdf" /></button>
        );
    }
}

export default CreateReportPDF;