import React from 'react';
import './report-selection-list.css';
import CreateReport from './create-report';
import { toast } from 'react-toastify';
import CreateReportPDF from './create-report-pdf';
import IndexedDBService from './../../services/IndexedDBService';

class ReportSelectionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { localReports: [], onlineReports: [], authToken: JSON.parse(localStorage.getItem('auth')), searchTerm: '', isUploading: false };
        this.handleCreatedReport = this.handleCreatedReport.bind(this);
    }

    componentDidMount() {
        this.getLocalReportData();
        this.getOnlineReports();
    }



    async loadThumbnailForReport(report) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (report.ThumbnailImageID === -1) {
                return resolve({ ...report, fileURL: '' });
            }
            const imagesRes = await (fetch('https://mcheck.alphademo.co.uk/api/images/' + report.ThumbnailImageID,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
                    }
                }));
            if (imagesRes.status === 200) {
                const image = await imagesRes.json();
                // const image = json[0];
                if (image) {
                    const fileRes = await (fetch('https://mcheck.alphademo.co.uk/api/files/' + image.Name,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
                            }
                        }
                    ));
                    if (fileRes.status === 200) {
                        const blob = await fileRes.blob();
                        const URL = window.URL.createObjectURL(blob);
                        const updatedReport = { ...report, fileURL: URL };
                        return resolve(updatedReport);
                    }
                } else {
                    return resolve({ ...report, fileURL: '' });
                }
            } else {
                return resolve({ ...report, fileURL: '' });
            }
        });
    }

    async loadOnlineThumbnails(reports) {
        const reportPromises = await Promise.allSettled(reports.map(async (report) => {
            const newReport = await this.loadThumbnailForReport(report);
            return newReport;
        }));
        const newReports = reportPromises.map((promise)=>promise.value);
        
        this.setState({ onlineReports: newReports });
    }

    loadLocalReportThumbnails(reports) {
        for (let i = 0; i < reports.length; i++) {
            if (!reports[i].LocalThumbnailImageID) break;
            IndexedDBService.getItemByBind('images', reports[i].LocalThumbnailImageID).then((image) => {
                const blob = new Blob([image.buffer], { type: image.fileType });
                const fileURL = window.URL.createObjectURL(blob);
                const newReports = this.state.localReports;
                const reportIndex = newReports.findIndex(x => x.id === image.reportID);
                newReports[reportIndex] = { ...newReports[reportIndex], fileURL };
                this.setState({ localReports: newReports });
            }).catch((error) => { console.error(error); toast.error('Error report thumbnail' + error); });
        }
    }

    getLocalReportData() {
        IndexedDBService.getList('reports').then((reports) => {
            this.setState({ localReports: reports });
            this.loadLocalReportThumbnails(reports);
        }).catch((error) => { console.error(error); toast.error('Error getting local reports' + error); });
    }

    getOnlineReports() {
        if (!(navigator.onLine)) {
            toast.error('No internet connection to show online reports');
            return;
        }

        const url = 'https://mcheck.alphademo.co.uk/api/reports?filter={"order":["CreatedOn DESC"]}';

        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.state.authToken.token
            },
        }).then(res => {
            if (!res.ok) {
                toast.error('Error accessing online database: ' + res.statusText);
            } else {
                res.json().then(json => {
                    this.setState({ onlineReports: json });
                    this.loadOnlineThumbnails(json);
                }).catch((err) => { console.error(err); toast.error(err); });
            }
        }).catch((err) => { console.error(err); toast.error(err); });
    }

    setImageAsThumbnail(reportID, ThumbnailImageID) {
        //Store image location in IndexedDB
        const requestDBOpen = window.indexedDB.open('test-db');
        requestDBOpen.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction(['reports'], 'readwrite');
            const objectStore = transaction.objectStore('reports');
            const thumbnialImageIDBind = IDBKeyRange.only(reportID);

            objectStore.openCursor(thumbnialImageIDBind).onsuccess = (event) => {
                const cursor = event.target.result;
                if (cursor) {
                    const data = cursor.value;
                    const request = objectStore.put({ ...data, LocalThumbnailImageID: ThumbnailImageID });

                    request.onsuccess = () => {
                        IndexedDBService.getItemByBind('images', ThumbnailImageID).then((image) => {
                            const blob = new Blob([image.buffer], { type: image.fileType });
                            const fileURL = window.URL.createObjectURL(blob);
                            const newReports = this.state.localReports;
                            const reportIndex = newReports.findIndex(x => x.id === image.reportID);
                            newReports[reportIndex] = { ...newReports[reportIndex], fileURL };
                            this.setState({ localReports: newReports });
                        }).catch((error) => { console.error(error); toast.error('Error report thumbnail' + error); });
                    };

                    request.onerror = (event) => {
                        toast.error('Error saving image as thumbnail' + event);
                        console.error(event);
                    };
                    cursor.continue();
                }
            };
        };
    }

    getImageData(image, localReportID, ThumbnailImageID) {
        return new Promise((resolve, reject) => {
            fetch('https://mcheck.alphademo.co.uk/api/files/' + image.Name,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
                    }
                }
            ).then((res) => {
                if (res.status === 404) {
                    toast.error('Image not found: ' + image.Name);
                    return reject();
                } else if (res.status !== 200) {
                    toast.error('Error getting image: ' + image.Name);
                    return reject();
                }

                res.blob().then((blob) => {
                    blob.arrayBuffer().then((arrayBuffer) => {

                        const imageObj = { reportID: localReportID, SeqNo: image.SeqNo, fileName: image.Name, fromDatabase: true, idOnBoard: image.ID, buffer: arrayBuffer, fileType: blob.type };
                        IndexedDBService.insertData('images', imageObj).then((event) => {
                            if (image.ID === ThumbnailImageID) {
                                this.setImageAsThumbnail(localReportID, event.event.target.result);
                            }
                            return resolve();
                        }).catch((error) => { console.error(error); toast.error('Error saving image file location' + error); });
                    });
                });
            });
        });
    }

    downloadImagesToReport(onlineReportID, localReportID, ThumbnailImageID) {
        this.setState({ isUploading: true });
        return new Promise((resolve) => {
            fetch('https://mcheck.alphademo.co.uk/api/reports/' + onlineReportID + '/images',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
                    }
                }
            ).then((res) => {
                res.json().then((json) => {
                    const imagePromises = [];
                    for (let image of json) {
                        imagePromises.push(this.getImageData(image, localReportID, ThumbnailImageID));
                    }
                    Promise.all(imagePromises).then(() => {
                        this.setState({ isUploading: false });
                        console.log(this.state.isUploading);
                        return resolve();
                    });
                });
            });
        });
    }

    async downloadReport(reportID) {
        this.setState({isUploading: true});
        const res = await (fetch('https://mcheck.alphademo.co.uk/api/reports/' + reportID,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
                }
            }
        ));
        if (res.status === 404) {
            toast.error('Report not found: ' + reportID);
            return;
        } else if (res.status !== 200) {
            toast.error('Error getting report: ' + reportID);
            return;
        }
        const recievedData = await res.json();
        recievedData.jobNumber = recievedData.jobnumber;
        recievedData.CreatedOn = (new Date(recievedData.CreatedOn)).toDateString();
        recievedData.reportIDOnBoard = reportID;
        recievedData.ArtPieceArray = JSON.parse(recievedData.ArtPieceArray);
        recievedData.DownloadedOn = (new Date()).toDateString();


        IndexedDBService.insertData('reports', recievedData).then((result) => {
            this.handleCreatedReport(result.event.target.result, recievedData);
            toast.promise(this.downloadImagesToReport(reportID, result.event.target.result, recievedData.ThumbnailImageID), {
                pending: 'Downloading Report',
                success: 'Report Downloaded',
                error: 'Failed to download report'
            });
        }).catch((error) => { console.error(error); toast.error('There was an error creating the report: \n' + error); });
        this.setState({isUploading: false});
    }

    setIsGeneratingPDF(value){
        this.setState({isUploading: value});
    }

    renderReportListElement() {
        const { localReports, onlineReports, searchTerm, isUploading } = this.state;

        const localReportAlreadyExistsFilterMatch = (onlineReport) => {
            const localIndex = localReports.findIndex((localReport) => localReport.reportIDOnBoard === onlineReport.ID);
            return localIndex === -1;
        };

        const searchFilterMatch = (report) => {
            const NameMatches = (report.Name?.toLowerCase().includes(searchTerm.toLowerCase()));
            const ArtTitleMatches = (report.ArtTitle?.toLowerCase().includes(searchTerm.toLowerCase()));
            const ArtistMatches = (report.Artist?.toLowerCase().includes(searchTerm.toLowerCase()));
            const CreatedByMatches = (report.Examiner?.toLowerCase().includes(searchTerm.toLowerCase()));
            return !searchTerm || (NameMatches || ArtTitleMatches || ArtistMatches|| CreatedByMatches);
        };

        const sort = (a, b) => {
            if (Object.hasOwn(a, 'id') && Object.hasOwn(b, 'id')) {
                if (b.id != a.id) {
                    return b.id - a.id;
                } else {
                    return new Date(b.CreatedOn) - new Date(a.CreatedOn);
                }
            } else {
                return new Date(b.CreatedOn) - new Date(a.CreatedOn);
            }

        };

        const reports = [...localReports.map((obj, index) => ({ ...obj, isOnline: false, index })), ...onlineReports.filter(localReportAlreadyExistsFilterMatch).map((obj, index) => ({ ...obj, isOnline: true, index }))];
        const listElement = reports.sort(sort).filter(searchFilterMatch).map((report) => {
            if (report.isOnline) {
                return (
                    <div key={'online' + report.ID} className="report-list-item">
                        <div className="image-container">{!report.fileURL ? <div /> : <img alt="Report Thumbnail" src={report.fileURL} />}</div>
                        <div className="info-section">
                            <h2>{report.Name}</h2>
                            <div className="report-list-item-desc secondary-text">
                                <div>Job Number: {report.JobNumber ? report.JobNumber : ''}</div>
                                <div>Client Reference: {report.ArtID ? report.ArtID : ''}</div>
                                <div>Artwork: {report.ArtTitle ? report.ArtTitle : ''}</div>
                                <div>Artist: {report.Artist ? report.Artist : ''}</div>
                                <div>Report Created On: {report.CreatedOn ? (new Date(report.CreatedOn)).toDateString() : ''}</div>
                                <div>Report Created By: {report.Examiner ? report.Examiner : ''}</div>
                            </div>
                        </div>
                        <div>
                            {/* <i className="primary fa-solid fa-cloud" /> */}
                            <button disabled={isUploading} className="primary create-pdf-button" onClick={() => { this.downloadReport(report.ID); }}><i className='fa-solid fa-download' /></button>
                            <CreateReportPDF reportID={report.ID} isUploading={isUploading} setIsGenerating={(e) => {this.setIsGeneratingPDF(e);}}/>
                        </div>
                    </div>);
            } else {
                return (
                    <div key={'local' + report.id} className="report-list-item">
                        <div className="image-container">{!report.fileURL ? <div /> : <img alt="Report Thumbnail" src={report.fileURL} />}</div>
                        <div className="info-section">
                            <h2>{report.Name}</h2>
                            <div className="report-list-item-desc secondary-text">
                                <div>Job Number: {report.JobNumber ? report.JobNumber : ''}</div>
                                <div>Client Reference: {report.ArtID ? report.ArtID : ''}</div>
                                <div>Artwork: {report.ArtTitle ? report.ArtTitle : ''}</div>
                                <div>Artist: {report.Artist ? report.Artist : ''}</div>
                                <div>Report Created On: {report.CreatedOn ? (new Date(report.CreatedOn)).toDateString() : ''}</div>
                                <div>Report Created By: {report.Examiner ? report.Examiner : ''}</div>
                                {Object.hasOwn(report, 'DownloadedOn') && <div>Report Downloaded On: {report.DownloadedOn ? report.DownloadedOn : ''}</div>}
                            </div>
                        </div>
                        <div>
                            <button className={'danger'} disabled={isUploading} onClick={() => { if (window.confirm('Are you sure you want to delete this report?')) { this.deleteLocalReport(report.id, report.index); } }}><i className="fa-solid fa-circle-minus" /></button>
                            <a className={'primary' + (isUploading ? ' disabled' : '')} href={'/Annotate?ID=' + report.id}> <i className="fa-regular fa-image" /> </a>
                            <a className={'primary' + (isUploading ? ' disabled' : '')} href={'/Form?ID=' + report.id}><i className="fa-regular fa-file" /></a>
                        </div>
                    </div>);
            }

        });

        return (<ul className="existing-reports-list">{listElement}</ul>);
    }

    deleteLocalReport(id, i) {
        IndexedDBService.deleteData('reports', id).then(() => {
            let tempReports = this.state.localReports;
            tempReports.splice(i, 1);
            this.setState({ localReports: tempReports });
            toast.success('A report was deleted! \n Report ID: ' + id);
        }).catch((error) => { console.error(error); toast.error('Error deleting report' + error); });
    }

    handleCreatedReport(newReportID, newReport) {
        const newreportList = [...this.state.localReports, { ...newReport, id: newReportID }];
        this.setState({ localReports: newreportList });
    }

    handleSearch(e) {
        this.setState({ searchTerm: e.target.value });
    }

    render() {
        return (
            <div className="report-list-container">
                <h1>Reports</h1>

                <div className="report-list">
                    <CreateReport handleCreatedReport={this.handleCreatedReport} />
                    <div className='searchReport form-group'>
                        <label>Search</label>
                        <input onChange={(e) => { this.handleSearch(e); }} />
                    </div>
                    {this.renderReportListElement()}

                </div>
                <hr />
            </div>

        );
    }
}

export default ReportSelectionList;