if (!('indexedDB' in window)) {
    window.alert("This browser doesn't support IndexedDB - A technology required for this app to work");
}

class IndexedDBService {
    constructor() {
        this.idb = window.indexedDB;
        this.db = null;
    }

    setupDB(callback) {
        return new Promise((resolve, reject) => {
            if (this.db) {
                typeof callback === 'function' && callback();
                resolve();
            }

            const requestDBOpen = this.idb.open('test-db', 2);

            requestDBOpen.onupgradeneeded = (event) => {
                this.db = event.target.result;
                if (!this.db.objectStoreNames.contains('reports')) {
                    this.db.createObjectStore('reports', { keyPath: 'id', autoIncrement: true });
                }
                if (!this.db.objectStoreNames.contains('images')) {
                    const imageObjectSTore = this.db.createObjectStore('images', { keyPath: 'id', autoIncrement: true });
                    imageObjectSTore.createIndex('reportID', 'reportID', { unique: false });
                }
                if (!this.db.objectStoreNames.contains('markers')) {
                    const markersObjectStore = this.db.createObjectStore('markers', { keyPath: 'id', autoIncrement: true });
                    markersObjectStore.createIndex('imageID', 'imageID', { unique: false });
                }
            };

            requestDBOpen.onsuccess = (event) => {
                this.db = event.target.result;
                typeof callback === 'function' && callback();
                resolve('Database open');
            };

            requestDBOpen.onerror = (event) => reject(event);
        });
    }

    async deleteDatabase() {
        return new Promise((resolve, reject) => {
            const deleteDatabaseRequest = window.indexedDB.deleteDatabase('test-db');
            deleteDatabaseRequest.onsuccess = () => resolve();
            deleteDatabaseRequest.onerror = () => reject();
        });
    }

    async getList(entity) {
        await this.setupDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([entity], 'readonly');
            const objectStore = transaction.objectStore(entity);
            const listToReturn = [];

            objectStore.openCursor().onsuccess = (event) => {
                const cursor = event.target.result;
                if (cursor) {
                    listToReturn.push(cursor.value);
                    cursor.continue();
                } else {
                    resolve(listToReturn);
                }
            };
            this.db.onerror = (event) => reject(event);
        });
    }

    async getListByBind(entity, valueToBind, fieldToBind) {
        await this.setupDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([entity], 'readonly');
            const objectStore = transaction.objectStore(entity);
            const index = fieldToBind ? objectStore.index(fieldToBind) : objectStore;
            const bind = IDBKeyRange.only(valueToBind);
            const listToReturn = [];

            index.openCursor(bind).onsuccess = (event) => {
                const cursor = event.target.result;
                if (cursor) {
                    listToReturn.push(cursor.value);
                    cursor.continue();
                } else {
                    resolve(listToReturn);
                }
            };
            this.db.onerror = (event) => reject(event);
        });
    }

    async getItemByBind(entity, valueToBind, fieldToBind) {
        await this.setupDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([entity], 'readonly');
            const objectStore = transaction.objectStore(entity);
            const index = fieldToBind ? objectStore.index(fieldToBind) : objectStore; //If no fieldToBind provided will bind on ID by default
            const bind = IDBKeyRange.only(valueToBind);
            index.openCursor(bind).onsuccess = (event) => {
                const cursor = event.target.result;
                if (cursor) {
                    resolve(cursor.value);
                    cursor.continue();
                }
            };
            this.db.onerror = (event) => reject(event);

        });
    }

    async insertData(entity, data) {
        await this.setupDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([entity], 'readwrite');
            const objectStore = transaction.objectStore(entity);
            const request = objectStore.add(data);
            request.onsuccess = (event) => resolve({event, data});
            request.onerror = (event) => reject(event);
        });
    }

    async putData(entity, data) {
        await this.setupDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([entity], 'readwrite');
            const objectStore = transaction.objectStore(entity);
            const request = objectStore.put(data);
            request.onsuccess = (event) => resolve({event, data});
            request.onerror = (event) => reject(event);
        });
    }

    async deleteData(entity, ID){
        await this.setupDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([entity], 'readwrite');
            const objectStore = transaction.objectStore(entity);
            const request = objectStore.delete(ID);
            request.onsuccess = (event) => resolve({event, ID});
            request.onerror = (event) => reject(event);
        });
    }
}

export default new IndexedDBService();