import React from 'react';
import './home.css';
import ImageAnnotator from '../core-components/image-anotator';


const Home = () => (
  <div>
    <ImageAnnotator />
  </div>
);

export default Home;