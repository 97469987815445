import React, { useEffect } from 'react';

const MicrosoftCallbackPage = () => {

    useEffect(() => {
        const params = new URLSearchParams(window.location.hash.split('#')[1]);
        const AccessToken = params.get('access_token');
        const AccessTokenExpiry = (Date.now()) + (parseInt(params.get('expires_in') * 1000));

        localStorage.setItem('access_token', AccessToken);
        localStorage.setItem('access_token_expiry', AccessTokenExpiry);

        window.location.href = '/';
    });

    return (
        <div>
            <h1>Microsoft Sign In</h1>
            <p>Signing you in</p>
        </div>
    );
};

export default MicrosoftCallbackPage;