import React from 'react';
import './reportForm.css';
import ReportForm from '../core-components/report-form';


const ReportFormPage = () => (
  <div>
    <ReportForm />
  </div>
);

export default ReportFormPage;