import React from 'react';
import './nav.css';


class NavFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <nav className='navigator'>
                <ul>
                    <li><a href='/'><i className='fa-solid fa-house fa-2xl' /></a></li>
                    <li><a href='/settings'><i className='fa-solid fa-gear fa-2xl' /></a></li>
                </ul>
            </nav>

        );
    }
}

export default NavFooter;