import React from 'react';
import './create-report.css';
import { toast } from 'react-toastify';
import IndexedDBService from './../../services/IndexedDBService';

class CreateReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = { enteredReportName: '', enteredJobNumber: '' };
    }

    componentDidMount() {
    }

    createReport() {
        const { enteredReportName, enteredJobNumber } = this.state;

        if (enteredReportName === '' || enteredJobNumber === '') {
            toast.error('You need to enter a name and job number');
            return;
        }

        const time = (new Date()).toISOString().split('T')[0];
        const data = {
            reportIDOnBoard: -1,
            Name: enteredReportName,
            JobNumber: enteredJobNumber,
            ThumbnailImageID: -1,
            ArtTitle: '',
            Artist: '',
            ArtCreationDate: '',
            ArtID: '',
            AltID: '',
            Client: '',
            ArtistSig: '',
            Desc: '',
            ArtDimmensions: [{ dimensions: { width: null, length: null, height: null }, mediums: [null], materials: [null] }],
            ConditionNotes: '',
            Examiner: '',
            ExaminerTitle: 'Technician',
            ExaminationLocation: '',
            ExaminationDate: time,
            CreatedOn: time
        };

        IndexedDBService.insertData('reports', data).then((result) => {
            toast.success('A report was created! \n New report ID: ' + result.event.target.result);
            this.props.handleCreatedReport(result.event.target.result, data);
            this.setState({ enteredJobNumber: '', enteredReportName: '' });
        }).catch((error) => { console.error(error); toast.error('There was an error creating the report: \n' + error); });

    }

    handleInput(e) {
        switch (e.target.id) {
            case 'report-name-input':
                this.setState({ enteredReportName: e.target.value });
                break;
            case 'job-number-input':
                this.setState({ enteredJobNumber: e.target.value });
                break;
        }
    }

    render() {
        return (
            <div className="report-list-item">
                <div className="info-section">
                    <div className="form-group">
                        <label>Report Name</label><br />
                        <input id="report-name-input" placeholder="e.g. Pre Install Images" value={this.state.enteredReportName} onChange={e => this.handleInput(e)} />
                    </div>
                    <div className="form-group">
                        <label>Job Number</label><br />
                        <input id="job-number-input" placeholder="e.g. MD0001" value={this.state.enteredJobNumber} onChange={e => this.handleInput(e)} />
                    </div>
                </div>
                <div className="create-button-container">
                    <button className="primary" onClick={() => { this.createReport(); }}><i className="fa-solid fa-plus" /></button>
                </div>
            </div>
        );
    }
}

export default CreateReport;