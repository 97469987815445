import React from 'react';
import './login.css';
import LoginForm from '../core-components/login-form';


const Login = () => (
  <div className="login-container">
    <h1>Login</h1>
    <LoginForm />
  </div>
);

export default Login;