import React from 'react';
import './reportSelection.css';
import ReportSelectionList from '../core-components/report-selection-list';


const ReportSelectionPage = () => (
  <div>
    <ReportSelectionList />
  </div>
);

export default ReportSelectionPage;