import React from 'react';
import './selection-input-control.css';

class SelectionInputControl extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showOptions: false,
            showOtherOverride: false,
            error: false
        };
    }

    handleInternalInput( inputId, isOption, option) {
        if (isOption) {
            this.setState({showOptions: false });
        }
        if(option == null){
            this.setState({error: true});
        } else {
            this.setState({error: false});
        }

        this.props.handleInput({key: inputId, value: option});
    }

    flipShowOptionsMenu() {
        if(this.state.showOptions && !this.props.selectedValue){
            this.setState({error: true});
        } else {
            this.setState({error: false});
        }
        this.setState({ showOptions: !this.state.showOptions });
    }

    showOther() {
        this.handleInternalInput(this.props.inputId, false, '');
        this.flipShowOptionsMenu();
        this.setState({showOtherOverride: true});
    }

    render() {
        const { inputId, inputClass, label, selectedValue, options, allowCustomOption } = this.props;
        const { showOptions, showOtherOverride } = this.state;
        const showOther = (!selectedValue && showOtherOverride) || (selectedValue && !options?.includes(selectedValue));
        const displayValue = selectedValue ? showOther ? 'Other' : selectedValue : 'Select...' ;
        const optionsArray = options.map((option, index) => <li className='selection-input-option' key={index} onClick={() => { this.handleInternalInput(inputId, true, option); }}>{option}</li>);
        
        return <div className={'form-group ' + inputClass + ' ' + (this.state.error ? 'error' : '')} id={inputId}>
            <label>{label}</label>
            <div className='selection-input-selected-option parent' onClick={() => { this.flipShowOptionsMenu(); }}>{displayValue} <i className="primary fa-solid fa-circle-chevron-down" /></div>
            {showOptions && <ul className='selection-input-list'>
                {optionsArray}
                {allowCustomOption && <li className='selection-input-option' onClick={() => { this.showOther(); }}> Other</li>}
            </ul>}
            {showOther &&
                <div>
                    <label> </label>
                    <input id={inputId + '-other'} placeholder='' value={selectedValue} onChange={(e) => { this.handleInternalInput( inputId, false, e.target.value); }} />
                </div>
            }
        </div>;
    }
}

export default SelectionInputControl;