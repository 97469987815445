import React from 'react';
import './marker-selector.css';
import { toast } from 'react-toastify';


class MarkerSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markerSize: 'medium'
        };
    }

    render() {
        const { createMarker, showMarkerSelector, handleCloseClicked } = this.props;

        return (
            <dialog id="markerSelectorModal" className="dialog" open={showMarkerSelector ? 'open' : false}>
                <div className='closeContainer'>
                    <button className="danger" onClick={handleCloseClicked}><i class='fa-regular fa-rectangle-xmark' /></button>
                </div>
                <p>Select Marker Size</p>
                <div className='optionsContainer'>
                    <button style={this.state.markerSize === 'small' ? { 'background-color': '#2979FF', 'color': 'white' } : {}} className="primary" onClick={() => { this.setState({ markerSize: 'small' }); toast.success('Marker size set to small'); }}>Small</button>
                    <button style={this.state.markerSize === 'medium' ? { 'background-color': '#2979FF', 'color': 'white' } : {}} className="primary" onClick={() => { this.setState({ markerSize: 'medium' }); toast.success('Marker size set to medium'); }}>Medium</button>
                    <button style={this.state.markerSize === 'large' ? { 'background-color': '#2979FF', 'color': 'white' } : {}} className="primary" onClick={() => { this.setState({ markerSize: 'large' }); toast.success('Marker size set to large'); }}>Large</button>
                </div>
                <p>Select Marker Type</p>
                <div className='optionsContainer'>
                    <button className="primary" onClick={() => { createMarker('Abrasion', ' ', 'green markersize-' + this.state.markerSize); }}>Abrasion</button>
                    <button className="primary" onClick={() => { createMarker('Accretion', ' ', 'green markersize-' + this.state.markerSize); }}>Accretion</button>
                    <button className="primary" onClick={() => { createMarker('Acid Burn', ' ', 'green markersize-' + this.state.markerSize); }}>Acid Burn</button>
                    <button className="primary" onClick={() => { createMarker('Acidic Mount', ' ', 'green markersize-' + this.state.markerSize); }}>Acidic Mount</button>
                    <button className="primary" onClick={() => { createMarker('Adhesive Residue', ' ', 'green markersize-' + this.state.markerSize); }}>Adhesive Residue</button>
                    <button className="primary" onClick={() => { createMarker('Broken', ' ', 'green markersize-' + this.state.markerSize); }}>Broken</button>
                    <button className="primary" onClick={() => { createMarker('Cockled', ' ', 'green markersize-' + this.state.markerSize); }}>Cockled</button>
                    <button className="primary" onClick={() => { createMarker('Cracks', ' ', 'green markersize-' + this.state.markerSize); }}>Cracks</button>
                    <button className="primary" onClick={() => { createMarker('Creases', ' ', 'green markersize-' + this.state.markerSize); }}>Creases</button>
                    <button className="primary" onClick={() => { createMarker('Delamination', ' ', 'green markersize-' + this.state.markerSize); }}>Delamination</button>
                    <button className="primary" onClick={() => { createMarker('Dent', ' ', 'green markersize-' + this.state.markerSize); }}>Dent</button>
                    <button className="primary" onClick={() => { createMarker('Discolouration', ' ', 'green markersize-' + this.state.markerSize); }}>Discolouration</button>
                    <button className="primary" onClick={() => { createMarker('Distortion', ' ', 'green markersize-' + this.state.markerSize); }}>Distortion</button>
                    <button className="primary" onClick={() => { createMarker('Fading', ' ', 'green markersize-' + this.state.markerSize); }}>Fading</button>
                    <button className="primary" onClick={() => { createMarker('Finger Marks', ' ', 'green markersize-' + this.state.markerSize); }}>Finger Marks</button>
                    <button className="primary" onClick={() => { createMarker('Foxing', ' ', 'green markersize-' + this.state.markerSize); }}>Foxing</button>
                    <button className="primary" onClick={() => { createMarker('Hinge Failure', ' ', 'green markersize-' + this.state.markerSize); }}>Hinge Failure</button>
                    <button className="primary" onClick={() => { createMarker('Inherent To Object', ' ', 'green markersize-' + this.state.markerSize); }}>Inherent To Object</button>
                    <button className="primary" onClick={() => { createMarker('Insect Damage', ' ', 'green markersize-' + this.state.markerSize); }}>Insect Damage</button>
                    <button className="primary" onClick={() => { createMarker('Lifting', ' ', 'green markersize-' + this.state.markerSize); }}>Lifting</button>
                    <button className="primary" onClick={() => { createMarker('Mitres Open', ' ', 'green markersize-' + this.state.markerSize); }}>Mitres Open</button>
                    <button className="primary" onClick={() => { createMarker('Moisture Damage', ' ', 'green markersize-' + this.state.markerSize); }}>Moisture Damage</button>
                    <button className="primary" onClick={() => { createMarker('Mould', ' ', 'green markersize-' + this.state.markerSize); }}>Mould</button>
                    <button className="primary" onClick={() => { createMarker('Pinhole', ' ', 'green markersize-' + this.state.markerSize); }}>Pinhole</button>
                    <button className="primary" onClick={() => { createMarker('Puncture', ' ', 'green markersize-' + this.state.markerSize); }}>Puncture</button>
                    <button className="primary" onClick={() => { createMarker('Previous Repair', ' ', 'green markersize-' + this.state.markerSize); }}>Previous Repair</button>
                    <button className="primary" onClick={() => { createMarker('Scratch', ' ', 'green markersize-' + this.state.markerSize); }}>Scratch</button>
                    <button className="primary" onClick={() => { createMarker('Skinning', ' ', 'green markersize-' + this.state.markerSize); }}>Skinning</button>
                    <button className="primary" onClick={() => { createMarker('Smudge', ' ', 'green markersize-' + this.state.markerSize); }}>Smudge</button>
                    <button className="primary" onClick={() => { createMarker('Stain', ' ', 'green markersize-' + this.state.markerSize); }}>Stain</button>
                    <button className="primary" onClick={() => { createMarker('Surface Dirt', ' ', 'green markersize-' + this.state.markerSize); }}>Surface Dirt</button>
                    <button className="primary" onClick={() => { createMarker('Tape', ' ', 'green markersize-' + this.state.markerSize); }}>Tape</button>
                    <button className="primary" onClick={() => { createMarker('Tear', ' ', 'green markersize-' + this.state.markerSize); }}>Tear</button>
                    <button className="primary" onClick={() => { createMarker('Tideline', ' ', 'green markersize-' + this.state.markerSize); }}>Tideline</button>
                    <button className="primary" onClick={() => { createMarker('Undulations', ' ', 'green markersize-' + this.state.markerSize); }}>Undulations</button>
                    <button className="primary" onClick={() => { createMarker('Warping', ' ', 'green markersize-' + this.state.markerSize); }}>Warping</button>
                    <button className="primary" onClick={() => { createMarker('Wrinkles', ' ', 'green markersize-' + this.state.markerSize); }}>Wrinkles</button>
                </div>
            </dialog>
        );
    }
}

export default MarkerSelector;