import React from 'react';
import html2canvas from 'html2canvas';
import './image-anotator.css';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import '../../scripts/jquery.ui.touch-punch.js';
import '../../scripts/jquery.image-marker.css';
import '../../scripts/jquery.image-marker.js';
import { toast } from 'react-toastify';
import IndexedDBService from '../../services/IndexedDBService';
import MarkerSelector from './marker-selector.jsx';

class ImageAnnotator extends React.Component {

  constructor(props) {
    super(props);
    const searchParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const reportIDParam = Number(searchParams.ID);

    this.state = {
      isUploading: false,
      images: [],
      selectedImage: '',
      selectedImageID: -1,
      reportID: reportIDParam,
      reportIDOnBoard: -1,
      JobNumber: '',
      Name: '',
      ThumbnailImageID: -1,
      LocalThumbnailImageID: -1,
      lastSavedDate: (new Date()).toLocaleTimeString(),
      showMarkerSelector: false
    };

    this.FileUploadRef = React.createRef();
    this.FileUploadToReplaceRef = React.createRef();
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleFileUploadToReplace = this.handleFileUploadToReplace.bind(this);
    this.createMarker = this.createMarker.bind(this);
    this.handleCloseClicked = this.handleCloseClicked.bind(this);
    this.saveToDB = this.saveToDB.bind(this);

    this.IDOfImageToReplace = -1;
  }

  componentDidMount() {
    this.getReportInfoFromDB();
    this.getImagesFromDB();

    if (navigator.storage) {
      navigator.storage.persisted().then((isPersisted) => {
        if (isPersisted) {
          navigator.storage.estimate();
        } else {
          navigator.storage.persist().then((result) => {
            if (result) {
              navigator.storage.estimate();
            }
          });
        }
      });
    }
    else {
      window.alert('This browser is unsupported (No navigator.storage available, perhaps you are on http not https?)');
      toast.error('This browser is unsupported (No navigator.storage available, perhaps you are on http not https?)');
    }
  }

  getReportInfoFromDB() {
    IndexedDBService.getItemByBind('reports', this.state.reportID).then((data) => {
      this.setState({
        ThumbnailImageID: data.ThumbnailImageID,
        LocalThumbnailImageID: data.LocalThumbnailImageID,
        JobNumber: data.JobNumber,
        Name: data.Name, reportIDOnBoard:
        data.reportIDOnBoard,
        CreatedOn: data.CreatedOn,
        report: {
          ArtCreationDate: data.ArtCreationDate,
          ArtDesc: data.ArtDesc,
          ArtID: data.ArtID,
          ArtTitle: data.ArtTitle,
          Artist: data.Artist,
          Client: data.Client,
          ConditionNotes: data.ConditionNotes,
          CreatedOn: data.CreatedOn,
          ExaminationDate: data.ExaminationDate,
          ExaminationLocation: data.ExaminationLocation,
          Examiner: data.Examiner,
          ExaminerTitle: data.ExaminerTitle,
          JobNumber: data.JobNumber,
          Name: data.Name
        }
      });
    }).catch((error) => { console.error(error); toast.error('Error getting report data ' + error); });
  }

  loadImageFiles(imageData) {
    const images = [];
    for (let image of imageData) {
      const blob = new Blob([image.buffer], { type: image.fileType });
      const src = window.URL.createObjectURL(blob);
      images.push({ src, ...image });
    }
    images.sort((a, b) => a.SeqNo - b.SeqNo);
    this.setState({ images: images, selectedImage: images[0] });
  }

  getImagesFromDB() {
    IndexedDBService.getListByBind('images', this.state.reportID, 'reportID').then((images) => {
      this.loadImageFiles(images);
    }).catch((error) => { console.error(error); toast.error('Error getting local images' + error); });
  }

  selectImage(image, imageID) {
    return new Promise((resolve) => {
      if (this.state.selectedImageID === imageID) {
        resolve();
        return;
      }
      this.saveToDB();
      $('#ImageMarker').remove();
      $('#ImageMarkerContainer').append($('<div id="ImageMarker" className="ImageMarker"></div>'));
      $('#ImageMarker').imageMarker({ src: image });

      this.setState({ selectedImageID: imageID, selectedImage: image });
      this.loadMarkersFromDB(imageID).then((markersLoaded) => { resolve({ markersLoaded }); });
      window.scrollTo(0, document.body.scrollHeight);
    });
  }

  createMarker(title, content, className) {
    $('#ImageMarker').trigger('add_marker', {
      title,
      content,
      className
    });
    this.handleCloseClicked();
  }

  loadMarkersFromDB(imageID) {
    return new Promise((resolve) => {
      IndexedDBService.getListByBind('markers', imageID, 'imageID').then((markers) => {
        for (const marker of markers) {
          $('#ImageMarker').trigger('add_marker', marker);
        }
        resolve(markers.length);
      }).catch((error) => { console.error(error); toast.error('Error getting local markers' + error); });
    });
  }

  saveToDB(close = false) {
    return new Promise((resolve) => {
      const selectedImageID = this.state.selectedImageID;
      if (!selectedImageID || selectedImageID === -1) { return resolve(); }
      this.setState({ lastSavedDate: (new Date()).toLocaleTimeString() });
      $('#ImageMarker').trigger('get_markers', function (markers) {
        const requestDBOpen = window.indexedDB.open('test-db');
        requestDBOpen.onsuccess = (event) => {
          const db = event.target.result;

          const transaction = db.transaction(['markers'], 'readwrite');
          const objectStore = transaction.objectStore('markers');

          const markersByImageIDIndex = objectStore.index('imageID');
          const selectedImageIDBind = IDBKeyRange.only(selectedImageID);

          markersByImageIDIndex.openCursor(selectedImageIDBind).onsuccess = (event) => {
            const cursor = event.target.result;
            if (cursor) {
              cursor.delete();
              cursor.continue();
            } else {
              markers.forEach((markerData) => {
                const request = objectStore.add({ ...markerData, imageID: selectedImageID });
                request.onsuccess = () => {
                  if(close){
                    window.location.href = '/';
                  }
                  // event.target.result
                };
              });
              resolve();
            }
          };
        };
      });
    });
  }

  async handleFileUpload(e) {
    //Get file information
    const file = e.target.files[0];
    const blob = new Blob([file], { type: file.type });
    const arrayBuffer = await blob.arrayBuffer();
    const fileType = file.type;
    const fileName = Date.now().toString() + '.' + file.type.split('/')[1]; //Type comes in the form image/type e.g. image/png

    //Store image location in IndexedDB
    const newSeq = this.state.images.length === 0 ? 0 : this.state.images[this.state.images.length - 1].SeqNo + 1;
    const image = { reportID: this.state.reportID, fileName, buffer: arrayBuffer, fileType, SeqNo: newSeq };
    IndexedDBService.insertData('images', image).then((result) => {
      const tempClientFilePath = window.URL.createObjectURL(blob);
      const newImagesList = [...this.state.images, { src: tempClientFilePath, id: result.event.target.result, SeqNo: newSeq }];
      if (this.state.images.length === 0) {
        this.setImageAsThumbnail(result.event.target.result);
      }
      this.setState({ images: newImagesList });
    }).catch((error) => { console.error(error); toast.error('Error saving image file location' + error); });
  }

  calculateImageSelectClass(imageID) {
    return this.state.selectedImageID === imageID ? 'image-select-btn selected' : 'image-select-btn';
  }

  async uploadImage(image) {
    const imageSrc = image.src;
    const imageID = image.id;
    const fileName = 'report' + this.state.reportIDOnBoard + '-' + crypto.randomUUID() + '.png';
    const FileUploadEndpoint = 'https://mcheck.alphademo.co.uk/api/files';
    let blob;

    if (image.fromDatabase) {
      blob = new Blob([image.buffer]);
    } else {
      const loadedImage = await this.selectImage(imageSrc, imageID);
      let elementToScreenshot = '#ImageMarkerContainer';
      if (loadedImage?.markersLoaded === 0) {
        elementToScreenshot = '.image-marker-container__img';
      }
      const canvas = await html2canvas(document.querySelector(elementToScreenshot), { scale: 2, foreignObjectRendering: false });
      blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
    }

    const formattedData = new FormData();
    formattedData.append('file', blob, fileName);
    const filePostResponse = await fetch(FileUploadEndpoint, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
      },
      body: formattedData
    });

    if (filePostResponse.status !== 200) {
      toast.error('Error uploading image file ' + filePostResponse.status);
      return 'Error uploading image file ' + filePostResponse.status;
    }

    const fileJson = await filePostResponse.json();
    if (fileJson.error) {
      toast.error('Error uploading image file' + fileJson.error.message);
      return 'Error uploading image file ' + fileJson.error.message;
    }

    const photoToUploadToBoard = { Name: fileName, SeqNo: image.SeqNo, Uploaded: true, isThumbnail: image.isThumbnail, FileLocation: 'https://mcheck.alphademo.co.uk/api/files/' + fileName, reportId: this.state.reportIDOnBoard };
    const tokenEndpointForImage = 'https://mcheck.alphademo.co.uk/api/images';

    const fileLocationUploadResponse = await fetch(tokenEndpointForImage, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
      },
      body: JSON.stringify(photoToUploadToBoard)
    });

    if (fileLocationUploadResponse.status !== 200) {
      toast.error('Error saving image location ' + fileLocationUploadResponse.status);
      return 'Error saving image location ' + fileLocationUploadResponse.status;
    }

    const fileLocationJson = await fileLocationUploadResponse.json();

    if (fileLocationJson.error) {
      toast.error('Error saving image location' + fileLocationJson.error.message);
      return 'Error saving image location' + fileLocationJson.error.message;
    }
    console.log('comparing', imageID + '/' + this.state.LocalThumbnailImageID);
    if (imageID === this.state.LocalThumbnailImageID) {
      console.log(fileLocationJson);
      await this.saveThumbnailImageIDToDB(fileLocationJson.ID);
    }
  }

  deleteReport(id) {
    IndexedDBService.deleteData('reports', id).then(() => {
      window.location.replace('/');
    }).catch((error) => { console.error(error); toast.error('Error deleting report' + error); });
  }

  async uploadReportToDatabase() {
    return new Promise((resolve, reject) => {
      const updatedData = {
        Name: this.state.Name
      };
      fetch('https://mcheck.alphademo.co.uk/api/reports/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
        },
        body: JSON.stringify(updatedData)
      }).then(res => {
        if (!res.ok) {
          toast.error('Error accessing online database: ' + res.statusText);
          reject();
          return;
        }
        res.json().then((jsonres) => {
          if (jsonres.error) {
            toast.error('Error uploading report: ' + jsonres.error.message);
            reject();
            return;
          }
          toast.success('Report uploaded sucessfully');

          const { enteredReportName, enteredJobNumber } = this.state;

          if (enteredReportName === '' || enteredJobNumber === '') {
            toast.error('You need to enter a name and job number');
            return;
          }

          const requestDBOpen = window.indexedDB.open('test-db');
          requestDBOpen.onsuccess = (event) => {
            const db = event.target.result;

            const transaction = db.transaction(['reports'], 'readwrite');
            const objectStore = transaction.objectStore('reports');

            const data = {
              id: this.state.reportID,
              reportIDOnBoard: jsonres.ID,
              Name: this.state.Name,
              JobNumber: this.state.JobNumber,
              CreatedOn: this.state.CreatedOn,
              ThumbnailImageID: this.state.ThumbnailImageID,
              ArtTitle: '',
              Artist: '',
              ArtCreationDate: '',
              ArtID: '',
              AltID: '',
              Client: '',
              ArtistSig: '',
              Desc: '',
              ArtDimmensions: [{ width: null, length: null, height: null }],
              ConditionNotes: '',
              Examiner: '',
              ExaminerTitle: '',
              ExaminationLocation: '',
              ExaminationDate: ''
            };


            const request = objectStore.put(data);

            request.onsuccess = () => {
              this.setState({ reportIDOnBoard: jsonres.ID });
              resolve();
              return;
            };
          };
        });
      }).catch((err) => {
        toast.error('Error uploading report', err);
        console.error(err);
        reject();
        return;
      });
    });
  }

  async getReportID() {
    if (this.state.reportIDOnBoard !== -1) {
      return this.state.reportIDOnBoard;
    }
    return this.uploadReportToDatabase();
  }

  deleteImagesOnDB(imagesToDelete) {
    const baseURL = 'https://mcheck.alphademo.co.uk/api/images/';
    const promises = [];
    for (let imageID of imagesToDelete) {
      promises.push(fetch(baseURL + imageID, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
        }
      }));
    }
    return Promise.all(promises);
  }

  async deleteAllExistingImagesOnDB() {
    return fetch('https://mcheck.alphademo.co.uk/api/reports/' + this.state.reportIDOnBoard + '/images', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
      }
    }).then(res => {
      if (res.status !== 200) {
        toast.error('Error deleting image' + res.status);
        throw new Error('Error deleting image ' + res.status);
      }
      res.json().then(jsonres => {
        if (jsonres.error) {
          toast.error('Error deleting image' + jsonres.error.message);
          throw new Error('Error deleting image' + jsonres.error.message);
        }
        return '';
      });
    }).catch((err) => {
      toast.error('Error deleting image' + err);
      throw new Error('Error deleting image' + err);
    });
  }

  saveThumbnailImageIDToDB(imageID) {
    return new Promise((resolve) => {
      const { reportIDOnBoard, reportID } = this.state;
      const endpoint = 'https://mcheck.alphademo.co.uk/api/reports/' + reportIDOnBoard;
      const method = 'PATCH';

      fetch(endpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth')).token
        },
        body: JSON.stringify({ ThumbnailImageID: imageID })
      }).then(res => {
        if (!res.ok) {
          toast.error('Error accessing online database: ' + res.statusText);
        } else {
          const requestDBOpen = window.indexedDB.open('test-db');
          requestDBOpen.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction(['reports'], 'readwrite');
            const objectStore = transaction.objectStore('reports');
            const thumbnialImageIDBind = IDBKeyRange.only(reportID);

            objectStore.openCursor(thumbnialImageIDBind).onsuccess = (event) => {
              const cursor = event.target.result;
              if (cursor) {
                const data = cursor.value;
                objectStore.put({ ...data, ThumbnailImageID: imageID });
                cursor.continue();
              }
              return resolve();
            };
          };
        }
      }).catch((err) => {
        toast.error('Error uploading report', err);
        console.error(err);
      });
    });
  }

  
  validateReportData(data){
    const errors = [];

    if(!data){
      return 'No report data found';
    }

    Object.entries(data).forEach(([key,value]) => {
        if(!value) errors.push(key + ' is not filled in');
    });

    return errors;
}

  async UploadAllImagesToDatabase() {
    if (!(navigator.onLine)) {
      toast.error('No internet connection to upload');
      throw new Error();
    }

    await this.saveToDB();
    if(!Object.hasOwn(this.state, 'reportIDOnBoard') || !this.state.reportIDOnBoard || this.state.reportIDOnBoard === -1){
      toast.error('You need to complete report form before you can finish uploading your report');
      throw new Error();
    }
    const validationErrors = this.validateReportData(this.state.report);
    if(validationErrors.length > 0) {
      toast.error('Missing report form info: ' + validationErrors.join(', '));
      throw new Error();
    }
    if(this.state.images.length === 0){
      toast.error('Please upload at least one image');
      throw new Error();
    }
    this.setState({ isUploading: true });
    await this.getReportID();

    console.log('state at upload', this.state);

    await this.deleteAllExistingImagesOnDB();
    for (let image of this.state.images) {
      await this.uploadImage(image);
    }
    this.setState({ isUploading: false });
    this.deleteReport(this.state.reportID);
  }

  deleteImage(e, id, i) {
    if (window.confirm('Are you sure you want to delete this image?')) {
      IndexedDBService.deleteData('images', id).then(() => {
        toast.success('An image was deleted! \n Image ID: ' + id);
        let tempArray = this.state.images;
        tempArray.splice(i, 1);
        this.setState({ images: tempArray });
      }).catch((error) => { console.error(error); toast.error('Error deleting report' + error); });
    }
  }

  setImageAsThumbnail(imageIDOverride) {
    const { selectedImageID, reportID } = this.state;

    //Store image location in IndexedDB
    const requestDBOpen = window.indexedDB.open('test-db');
    requestDBOpen.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['reports'], 'readwrite');
      const objectStore = transaction.objectStore('reports');
      const thumbnialImageIDBind = IDBKeyRange.only(reportID);

      objectStore.openCursor(thumbnialImageIDBind).onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          console.log(imageIDOverride || selectedImageID, cursor.value);
          const data = cursor.value;
          const request = objectStore.put({ ...data, LocalThumbnailImageID: imageIDOverride || selectedImageID });

          request.onsuccess = () => {
            toast.success('Saved image as thumbnail');
            this.setState({ LocalThumbnailImageID: imageIDOverride || selectedImageID });
          };

          request.onerror = (event) => {
            toast.error('Error saving image as thumbnail' + event);
            console.error(event);
          };
          cursor.continue();
        }
      };
    };
  }

  replaceImage(e, id, i) {
    if (window.confirm('Are you sure you want to delete this image?')) {
      IndexedDBService.deleteData('images', id).then(() => {
        toast.success('An image was deleted! \n Image ID: ' + id);
        let tempArray = this.state.images;
        tempArray.splice(i, 1);
        this.setState({ images: tempArray });
      }).catch((error) => { console.error(error); toast.error('Error deleting report' + error); });
    }
  }

  async handleFileUploadToReplace(e) {
    const indexOfImageToReplace = this.state.images.findIndex(image => image.id === this.IDOfImageToReplace);
    const SeqNo = this.state.images[indexOfImageToReplace].SeqNo;
    IndexedDBService.deleteData('images', this.IDOfImageToReplace).then(() => {
      let tempArray = this.state.images;
      tempArray.splice(indexOfImageToReplace, 1);
      this.setState({ images: tempArray });
    }).catch((error) => { console.error(error); toast.error('Error deleting replaced image' + error); });

    //Get file information
    const file = e.target.files[0];
    const blob = new Blob([file], { type: file.type });
    const arrayBuffer = await blob.arrayBuffer();
    const fileType = file.type;
    const fileName = Date.now().toString() + '.' + file.type.split('/')[1]; //Type comes in the form image/type e.g. image/png

    //Store image location in IndexedDB
    const image = { reportID: this.state.reportID, fileName, buffer: arrayBuffer, fileType, SeqNo };
    IndexedDBService.insertData('images', image).then((result) => {
      const tempClientFilePath = window.URL.createObjectURL(blob);
      const newImagesList = [...this.state.images, { src: tempClientFilePath, id: result.event.target.result, SeqNo }].sort((a, b) => a.SeqNo - b.SeqNo);
      if (this.state.images.length === 0) {
        this.setImageAsThumbnail(result.event.target.result);
      }
      this.setState({ images: newImagesList });
    }).catch((error) => { console.error(error); toast.error('Error saving image file location' + error); });
  }

  handleCloseClicked() {
    this.setState({ showMarkerSelector: false });
  }

  render() {
    const { selectedImageID, isUploading, LocalThumbnailImageID } = this.state;
    let isOnlineReport = false;
    const imageListElement = this.state.images.map((image, index) => {
      const { id, src, fromDatabase } = image;
      if (fromDatabase) {
        isOnlineReport = true;
        return <div key={id} className={'image-container ' + this.calculateImageSelectClass(id)}>
          <button disabled={isUploading} className={this.calculateImageSelectClass(id)}>
            <img alt="Report Thumbnail" className="image-list-thumbnail online-image" src={src} />
            <div>
              <label className="image-list-label"> </label>
            </div>
          </button>
          <div className='image-buttons'>
            <button className="danger" disabled={isUploading} onClick={() => { this.IDOfImageToReplace = id, this.FileUploadToReplaceRef.current.click(); }}><i className="fa-solid fa-arrows-spin" /></button>
            <button className="danger" disabled={isUploading} onClick={(e) => { this.deleteImage(e, id, index); }}><i className="fa-solid fa-circle-minus" /></button>
            <button className="primary" disabled={isUploading} onClick={() => { this.setImageAsThumbnail(id); }}><i className="fa-solid fa-thumbtack" /></button>
          </div>
        </div>;
      }
      return <div key={id} className={'image-container ' + this.calculateImageSelectClass(id)}>
        <button disabled={isUploading} className={this.calculateImageSelectClass(id)} onClick={() => { this.selectImage(src, id); }}>
          <div className="image-list-thumbnail-container">
            <img alt="Report Thumbnail" className="image-list-thumbnail" src={src} />
          </div>
          <div>
            <label className="image-list-label">{image.id === LocalThumbnailImageID && <i className="primary fa-solid fa-thumbtack" />}</label>
          </div>
        </button>
        <div className='image-buttons'>
          <button className="danger" disabled={isUploading} onClick={() => { this.IDOfImageToReplace = id, this.FileUploadToReplaceRef.current.click(); }}><i className="fa-solid fa-arrows-spin" /></button>
          <button className="danger" disabled={isUploading} onClick={(e) => { this.deleteImage(e, id, index); }}><i className="fa-solid fa-circle-minus" /></button>
          <button className="primary" disabled={isUploading} onClick={() => { this.setImageAsThumbnail(id); }}><i className="fa-solid fa-thumbtack" /></button>
        </div>
      </div>;
    });

    return (
      <div className="container">
        <h1>Images</h1>
        {isOnlineReport && <p>Editing an online report</p>}
        <div className="imageList">
          <button disabled={isUploading} className="file-upload-btn primary" onClick={() => { this.FileUploadRef.current.click(); }}><i className="fa-solid fa-file-circle-plus" /></button>
          <button className="primary" disabled={isUploading} onClick={() => {
            toast.promise(this.UploadAllImagesToDatabase(), {
              pending: 'Uploading Report',
              success: 'Report Uploaded',
              error: 'Failed to upload report'
            });
          }}><i className="fa-solid fa-cloud-arrow-up" /></button>
          {imageListElement}
        </div>
        <div className="upload-controls">
          <input disabled={isUploading} style={{ display: 'none' }} type="file" name="img" accept="image/*" onChange={this.handleFileUpload} ref={this.FileUploadRef} />
          <input disabled={isUploading} style={{ display: 'none' }} type="file" name="img" accept="image/*" onChange={this.handleFileUploadToReplace} ref={this.FileUploadToReplaceRef} />
        </div>
        {selectedImageID !== -1 && <div>
          <div className="control-panel">
            <button className="primary" disabled={isUploading} onClick={() => { this.setState({ showMarkerSelector: true }); }}><i className="fa-solid fa-pencil" /></button>
            <button className="primary" disabled={isUploading} onClick={() => { this.saveToDB(true); }}><i className="fa-solid fa-floppy-disk" /></button>
          </div>
        </div>}
        <div id="ImageMarkerContainer" className="ImageMarkerContainer" />
        <MarkerSelector showMarkerSelector={this.state.showMarkerSelector} createMarker={this.createMarker}  handleCloseClicked={this.handleCloseClicked}/>
      </div>

    );
  }
}

export default ImageAnnotator;