import React from 'react';
import { toast } from 'react-toastify';
import logo from '../../assets/MTEC_LOGO.jpg';
import './login-form.css';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { enteredUsername: '', enteredPassword: '', isLoggingIn: false };
    }

    componentDidMount() {
    }

    postLoginRequest() {
        return new Promise((resolve, reject) => {
            const url = 'https://mcheck.alphademo.co.uk/api/users/login';

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: this.state.enteredUsername, password: this.state.enteredPassword })
            }).then(res => {
                if (!res.ok) {
                    toast.error('Error accessing online database: ' + res.statusText);
                    reject();
                } else {
                    res.json().then(json => {
                        const token = json.token;
                        const expiry = Math.floor((new Date(Date.now() + 6 * (60 * 60 * 1000))).getTime() / 1000);
                        localStorage.setItem('auth', JSON.stringify({ token, expiry }));
                        toast.success('Logged in!');
                        if (window.location.pathname !== '/login') {
                            window.location.reload();
                        } else {
                            window.location.pathname = '/Home';
                        }
                        resolve();
                    }).catch((err) => { console.error(err); toast.error(err); });
                }
            }).catch((err) => { console.error(err); toast.error(err); reject(); }).finally(()=>{this.setState({isLoggingIn: false});});
        });
    }

    login() {
        this.setState({isLoggingIn: true});
        toast.promise(this.postLoginRequest(),{
            pending: 'Logging In',
            success: 'Logged In',
            error: 'Login Failed'
        });
    }

    handleInput(e) {
        switch (e.target.id) {
            case 'username-input':
                this.setState({ enteredUsername: e.target.value });
                break;
            case 'password-input':
                this.setState({ enteredPassword: e.target.value });
                break;
        }
    }

    render() {
        return (
            <div className="container">
                <img className="login-logo" src={logo} />
                <div className="form-group">
                    <label>Username</label><br />
                    <input id="username-input" placeholder="" value={this.state.enteredUsername} onChange={e => this.handleInput(e)} />
                </div>
                <div className="form-group">
                    <label>Password</label><br />
                    <input id="password-input" type="password" value={this.state.enteredPassword} onChange={e => this.handleInput(e)} />
                </div>
                <div className="create-button-container">
                    <button className="primary" disabled={this.state.isLoggingIn} onClick={() => { this.login(); }}>Login</button>
                </div>
            </div>
        );
    }
}

export default LoginForm;