import React from 'react';
import './App.css';
import { HomePage, ReportSelectionPage, SettingsPage, Login } from './components/pages';
import NavFooter from './components/core-components/nav';
import MicrosoftCallbackPage from './components/pages/microsoftCallback';
import ReportFormPage from './components/pages/reportForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IndexedDBService from './services/IndexedDBService';

function App() {
  IndexedDBService.setupDB();

  let component;
  const storedAuthToken = localStorage.getItem('auth');
  const now = Math.floor(Date.now()/1000);
  if ((!storedAuthToken || JSON.parse(storedAuthToken).expiry < now) && navigator.onLine)  {
    component = <Login />;
  } else {
    switch (window.location.pathname) {
      case '/Login':
        component = <Login />;
        break;
      case '/Annotate':
        component = <HomePage />;
        break;
      case '/Form':
        component = <ReportFormPage />;
        break;
      case '/Selectreport':
        component = <ReportSelectionPage />;
        break;
      case '/settings':
        component = <SettingsPage />;
        break;
      case '/callback':
        component = <MicrosoftCallbackPage />;
        break;
      case '/':
        component = <ReportSelectionPage />;
        break;
      default:
        component = <ReportSelectionPage />;
        break;
    }
  }

  return (
    <div className='App'>
      <div className='main-content'>
        {component}
      </div>
      <NavFooter />
      <ToastContainer position='bottom-left' />
    </div>
  );
}

export default App;
