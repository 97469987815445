import React from 'react';
import './input-control.css';

class InputControl extends React.Component {
    constructor(props) {
        super(props);
        this.handleBlur = this.handleBlur.bind(this);
        this.state = {
            error: false
        };
    }

    handleBlur(e, type){
        if(type==='num'){
            if(/[^0-9\-.]/.test(e.target.value)){
                this.setState({error: true});
            } else{
                this.setState({error: false});
            }
        }
    }

    render() {
        const { inputId, inputClass, label, disabled, type, placeholder, value, handleInput } = this.props;

        if (inputClass === 'big') {
            return (
                <div className={'form-group ' + inputClass + ' ' + (this.state.error ? 'error' : '')}>
                    <label>{label}</label>
                    <textarea onBlur={(e) => {this.handleBlur(e,type);}} disabled={disabled} maxLength={150} type={type} placeholder={placeholder} value={value || ''} onChange={e => handleInput({e, key: inputId, value: e.target.value, inputType: type})} />
                </div> 
            );
        } else {
            return (
                <div className={'form-group ' + inputClass + ' ' + (this.state.error ? 'error' : '')}>
                    <label>{label}</label>
                    <input onBlur={(e) => {this.handleBlur(e,type);}} disabled={disabled} type={type} maxLength={150} inputMode={type === 'number' ? 'numeric' : null} pattern={type === 'number' ? '[0-9]*' : null} placeholder={placeholder} value={value || ''} onChange={e => handleInput({e, key: inputId, value: e.target.value, inputType: type})} />
                </div>
            );
        }
    }
}

export default InputControl;