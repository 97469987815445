import React from 'react';
import './settings.css';
import { toast } from 'react-toastify';
import IndexedDBService from '../../services/IndexedDBService';


class SettingsList extends React.Component {
    constructor(props) {
        super(props);
        const AccessToken = localStorage.getItem('access_token');
        const AccessTokenExpiry = new Date(parseInt(localStorage.getItem('access_token_expiry'))).toString();
        this.state = { GraphAccessToken: AccessToken, GraphAccessTokenExpiry: AccessTokenExpiry, availableStorage: '', usedStorage: '' };
    }

    componentDidMount() {
        if (navigator.storage) {
            navigator.storage.persisted().then((isPersisted) => {
                if (isPersisted) {
                    navigator.storage.estimate().then((estimation) => {
                        this.setState({ availableStorage: (Math.round(estimation.quota / (1024 * 1024))) + 'mb', usedStorage: (Math.round(estimation.usage / (1024 * 1024))) + 'mb' });
                    });
                }
            });
        }
    }

    async clearOPFS() {
        const storageRoot = await navigator.storage.getDirectory();
        let folderHandle = await storageRoot.getDirectoryHandle('images').catch(()=> 0);
        if (folderHandle) {
            await folderHandle.remove({ recursive: true });
        }
    }


    async requestClearData() {
        return IndexedDBService.deleteDatabase().then(()=>{
            this.setState({ usedStorage: '0mb' });
        });
    }

    ClearData() {
        toast.promise(this.requestClearData(),{
            pending: 'Clearing',
            success: 'Data Cleared',
            error: 'Failed to clear data'
        });
    }

    render() {
        return (
            <div className="container settingsContainer">
                <h1>Settings</h1>
                <p>Version 27/06/2024 08:15</p>
                <section>
                    <h2>Storage</h2>
                    <div className="form-group">
                        <label>Available Storage</label>
                        <input value={this.state.availableStorage} disabled />
                    </div>
                    <div className="form-group">
                        <label>Used Storage</label>
                        <input value={this.state.usedStorage} disabled />
                    </div>
                    <button onClick={() => { this.ClearData(); }}>Clear Site Data</button>
                </section>


            </div>

        );
    }
}

export default SettingsList;