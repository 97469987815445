import React from 'react';
import './settings.css';
import SettingsList from '../core-components/settings';


const SettingsPage = () => (
  <div>
    <SettingsList />
  </div>
);

export default SettingsPage;